















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient,
} from '@/modules/scheduled-reports/interfaces';
import DEFAULT_LOS from '@/modules/document-filters/constants/default-los.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import loop24 from '@/modules/common/filters/loop-24.filter';
import MealTypeModel from '@/modules/meal-types/models/meal-type.model';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import RoomTypeModel from '@/modules/room-types/models/room-type.model';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import RatesService, { RatesServiceS } from '../../rates.service';
import RatesFiltersService, { RatesFiltersServiceS } from '../../rates-filters.service';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '../../rates-analysis-filters.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '../../rates-analysis.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(RatesServiceS) private ratesService!: RatesService;
    @Inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;
    @Inject(RatesAnalysisFiltersServiceS) private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;
    @Inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;
    @Inject(MealTypesServiceS) private mealTypesService!: MealTypesService;
    @Inject(RoomTypesServiceS) private roomTypesService!: RoomTypesService;
    @Inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;

    readonly dataType = DATA_TYPE.RATES_COMPARE;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            dateRange: {
                options: [30, 60, 90],
                value: 30,
            },
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        if (this.ratesService.isLoading) {
            return filters;
        }

        if (this.compsetsService.currentCompset) {
            const options = this.compsetsService.compsets!
                .map(compset => ({
                    name: compset.name,
                    value: compset.id,
                }));
            const value = this.compsetsService.currentCompset.id;

            filters.push({
                name: 'compset',
                label: 'Comp Set',
                value,
                options,
                disableOnEdit: true,
            });
        }

        if (this.documentFiltersService.settings.pos) {
            filters.push({
                name: 'pos',
                label: 'POS',
                value: this.documentFiltersService.settings.pos,
                options: this.documentFiltersService.posRatesItems,
                disableOnEdit: false,
            });
        }

        if (this.ratesFiltersService.settings.provider) {
            filters.push({
                name: 'providers',
                label: 'Source',
                value: this.ratesFiltersService.settings.provider,
                options: this.compsetsService.currentCompset!.rateProviders
                    .filter(p => p !== 'all')
                    .map(p => ({
                        name: this.providersService.getProviderLabel(p),
                        value: p,
                    })),
                disableOnEdit: false,
            });
        }

        if (this.documentFiltersService.settings.los) {
            filters.push({
                name: 'los',
                label: 'LOS',
                value: this.documentFiltersService.settings.los || DEFAULT_LOS[0],
                options: DEFAULT_LOS.map(l => ({
                    name: String(l),
                    value: l,
                })),
                disableOnEdit: false,
            });
        }

        const { mealTypes } = this.mealTypesService;
        if (mealTypes) {
            const { mealTypeId } = this.ratesFiltersService.settings;
            filters.push({
                name: 'meal_type',
                label: 'Meal Type',
                value: mealTypeId === ANY_MEAL_TYPE.id
                    ? mealTypes.filter(mt => mt.id !== ANY_MEAL_TYPE.id).map(mt => mt.name)
                    : [this.mealTypesService.getMealType(mealTypeId)!.name],
                options: mealTypes
                    .filter(mt => mt.id !== ANY_MEAL_TYPE.id)
                    .map((mealType: MealTypeModel) => ({
                        value: mealType.name,
                        name: mealType.displayName,
                    })),
                disableOnEdit: false,
            });
        }

        const { rooms } = this.roomTypesService;
        if (rooms) {
            const { roomTypeId } = this.ratesFiltersService.settings;
            filters.push({
                name: 'room_type',
                label: 'Room Type',
                value: roomTypeId === ANY_ROOM_TYPE.id
                    ? rooms.filter(rt => rt.id !== ANY_ROOM_TYPE.id).map(room => room.name)
                    : [this.roomTypesService.getRoomType(roomTypeId)!.name],
                options: rooms
                    .filter(rt => rt.id !== ANY_ROOM_TYPE.id)
                    .map((room: RoomTypeModel) => ({
                        value: room.name,
                        name: room.name,
                    })),
                disableOnEdit: false,
            });
        }

        filters.push({
            name: 'occupancy',
            label: 'Occupancy',
            value: this.ratesFiltersService.settings.numberOfGuests,
            options: Array
                .from({ length: 10 })
                .map((_, i) => i + 1)
                .map(e => ({
                    value: e,
                    name: e + (e > 1 ? ' guests' : ' guest'),
                })),
            disableOnEdit: false,
        });

        filters.push({
            name: 'price',
            label: 'Price Type',
            value: this.ratesFiltersService.settings.priceType,
            options: [
                {
                    name: 'Lowest',
                    value: PRICE_TYPE.LOWEST,
                },
                {
                    name: 'Lowest Flex',
                    value: PRICE_TYPE.LOWEST_FLEX,
                },
                {
                    name: 'Best Flex',
                    value: PRICE_TYPE.BEST_FLEX,
                },
                {
                    name: 'Non Refundable',
                    value: PRICE_TYPE.NON_REFUNDABLE,
                },
            ],
            disableOnEdit: false,
        });

        let priceTypeValue = 'shown_price';
        switch (this.ratesFiltersService.settings.priceShown) {
            case PRICE_SHOWN.TOTAL:
                priceTypeValue = 'total_price';
                break;
            case PRICE_SHOWN.NET:
                priceTypeValue = 'net_price';
                break;
            default:
                priceTypeValue = 'shown_price';
                break;
        }

        filters.push({
            name: 'price_type',
            label: 'Price',
            value: priceTypeValue,
            options: [
                { value: 'shown_price', name: PRICE_SHOWN.SHOWN },
                { value: 'net_price', name: PRICE_SHOWN.NET },
                { value: 'total_price', name: PRICE_SHOWN.TOTAL },
            ],
            disableOnEdit: false,
        });

        const compareList = [
            ['past period', 'Past Period'],
            ['source', 'Source'],
            ['mealType', 'Meal Type'],
            ['roomType', 'Room Type'],
            ['los', 'LOS'],
            ['pos', 'POS'],
            ['number of guest', 'Number of Guests'],
            ['price', 'Price'],
        ];

        const { settings: ratesSettings } = this.ratesFiltersService.storeState;
        const { settings: docSettings } = this.documentFiltersService.storeState;
        const compareValues: { [k: string]: () => Item[] } = {
            // eslint-disable-next-line no-useless-computed-key
            ['past period']: () => this.ratesAnalysisFiltersService.comparisonDayItems,

            source: () => this.ratesAnalysisFiltersService.providerItems,

            mealType: (wholeList = false) => this.mealTypesService.mealTypes
                .filter(m => wholeList || (m.id !== -1 && m.id !== ratesSettings.mealTypeId))
                .map(m => ({ value: m.name, name: m.displayName })),

            roomType: (wholeList = false) => this.roomTypesService.rooms
                .filter(r => wholeList || (r.id !== -1 && r.id !== ratesSettings.roomTypeId))
                .map(r => ({ value: r.name, name: r.name })),

            los: (wholeList = false) => {
                const { losItems } = this.documentFiltersService;
                const defaultLosItems = DEFAULT_LOS.map(value => ({
                    value,
                    name: `LOS${value}`,
                })) as Item[];

                const { isScanDisabledProvider } = this.ratesFiltersService;
                const targetList = isScanDisabledProvider
                    ? losItems
                    : defaultLosItems;

                if (wholeList) {
                    return targetList;
                }

                return targetList
                    .filter(l => l.value !== docSettings.los);
            },

            pos: (wholeList = false) => this.documentFiltersService.posRatesItems
                .filter(item => wholeList || item.value !== docSettings.pos),

            // eslint-disable-next-line no-useless-computed-key
            ['number of guest']: (wholeList = false) => Array.from({ length: 10 })
                .map((_, i) => ({ value: i + 1, name: String(i + 1) }))
                .filter(nog => wholeList || nog.value !== ratesSettings.numberOfGuests),

            price: (wholeList = false) => Object
                .values(PRICE_TYPE)
                .filter(v => wholeList || v !== ratesSettings.priceType)
                .map(value => ({ value, name: value[0].toUpperCase() + value.slice(1, value.length).replace('_', ' ') })),
        };

        filters.push({
            name: 'comparison_to',
            label: 'Compare to',
            value: [compareList[0][0], compareValues[compareList[0][0]]()[0].value],
            options: [compareList.map(item => ({
                name: item[1],
                value: item[0],
            })), Object.entries(compareValues).map(([_, value]) => value())],
            disableOnEdit: [false, false],
        });

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
